@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,800&display=swap');

.o-title-page{
  font-family: 'Barlow Semi Condensed';
  text-transform: uppercase;
  font-size: 10rem;
  line-height: 10rem;
  letter-spacing: 0.2vm;
  color: rgb(10, 4, 22);
  -webkit-text-stroke: 1.4px rgba(105, 52, 122, 0.877);  
}
@media (max-width: 767px) {
  .o-title-page{
    font-size: 3rem;
    line-height: 3rem;
    padding-top: 20px;
  }
}
.o-title-block{
  font-family: 'Barlow Semi Condensed';
  text-transform: uppercase;
  font-size: 5rem;
  letter-spacing: 0.2vm;
  padding-bottom: -1px;
  color: rgb(10, 4, 22);
  -webkit-text-stroke: 1.4px rgba(238, 229, 241, 0.877);  
}
.o-sub-title{
  font-family: 'Barlow Semi Condensed', 'sans-serif';
  font-size: 2.7rem;
  letter-spacing: 0.2vm;
  color: rgba(209, 14, 144, 0.5);
  margin-top: -23px !important;
}
.o-hero-title{
  font-family: 'Barlow Semi Condensed', 'sans-serif';
  font-size: 1.7rem;
  letter-spacing: 0.2vm;
  color: rgba(235, 62, 10, 0.877);
}

.textAnimation{
  font-family: 'Barlow Semi Condensed', 'sans-serif';
  font-size: 3.8rem;
  letter-spacing: 0.2vm;
  color: rgba(253, 251, 252, 0.979);
}
.h2, .h3, h4, .h5{
  font-family: 'PoppinsBold';
}
.section-title{
  font-size: 1.4rem;
  font-family: 'PoppinsBold';
  letter-spacing: 1.4rem;
  margin-bottom: 3rem;
  text-transform: uppercase;
  color: rgba(235, 62, 10, 0.877);
}
@media (max-width: 767px) {
  .section-title{
    font-size: 0.8rem;
    letter-spacing: 0.8rem;    
  }
}
@media only screen and (max-width: 767px) {
  .btn-oval.btn-xl{
    width: 145px !important;
    font-size: 15px !important;
  }
}
.btn-oval.btn-xl{
  width: 185px;
  height: 45px;
  padding: 10px 0px;
  border-radius: 23px;
  border-color: bisque;
  text-align: center;
  font-size: 17px;
  color: antiquewhite;
  line-height: 1.42857;
  position: relative;
  z-index: 0;
  outline: none;
}
.btn-oval.btn-x{
  width: 215px;
  height: 45px;
  padding: 10px 0px;
  border-radius: 23px;
  border-color: bisque;
  text-align: center;
  font-size: 17px;
  color: antiquewhite;
  line-height: 1.42857;
  position: relative;
  z-index: 0;
  outline: none;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: rgb(248, 242, 242)
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  color: rgb(248, 242, 242);
  background: rgba(209, 14, 144, 0.5);
  left: 0;
  top: 0;
  border-radius: 23px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}
/* .btn-oval.btn-xl:hover{
  background: linear-gradient(to right, rgba(209, 14, 144, 0.5),rgba(29, 9, 211, 0.5));
  color: antiquewhite !important;
} */
.App, .about-section, .home-section, .contact-section, .solution-section {
  background-color: rgb(10, 4, 22);
  margin-top: 4rem;
  }

/* --------- */
/* Header Section  */
/* --------- */
.x2.x3{
  text-align: left;
}
.fristsp1{
  color: rgba(223, 32, 58, 0.884);
  font-weight: bold;
  -webkit-text-stroke: 1.4px rgba(245, 242, 247, 0.979);
  padding-right: 10px;
}
.fristsp2{
  color: rgba(210, 223, 32, 0.884);
  font-weight: bolder;
  -webkit-text-stroke: 1.4px rgba(21, 9, 189, 0.979);
  padding-right: 10px;
}
.fristsp3{
  color: rgba(16, 191, 197, 0.884);
  font-weight: bolder;
  -webkit-text-stroke: 1.4px rgba(245, 242, 247, 0.979);
  padding-right: 10px;
}
.secondsp1{
  color: rgba(231, 137, 14, 0.884);
  font-weight: bolder;
  -webkit-text-stroke: 1.4px rgba(9, 189, 90, 0.979);  
}
.secondsp2{
  color: rgba(197, 16, 167, 0.884);
  font-weight: bolder;
  -webkit-text-stroke: 1.4px rgba(245, 242, 247, 0.979);
  padding-right: 20px;
}
.secondsp3{
  color: rgba(96, 11, 122, 0.884);
  font-weight: bold;
  -webkit-text-stroke: 1.4px rgba(50, 44, 54, 0.979);  
}
.threesp1{
  color: rgba(238, 155, 45, 0.884);
  font-weight: bolder;
  -webkit-text-stroke: 1.4px rgba(30, 6, 97, 0.979);  
}
.threesp2{
  color: rgba(114, 41, 102, 0.884);
  font-weight: bolder;
  -webkit-text-stroke: 1.4px rgba(19, 48, 26, 0.979);
  padding-right: 20px;
}
.threesp3{
  color: rgba(52, 88, 5, 0.884);
  font-weight: bold;
  -webkit-text-stroke: 1.4px rgba(218, 201, 230, 0.979);  
}
.hero-text{
  margin-top: 2.5rem !important;
}
.top-social-buttons{
  float: right;
}
.top-oncontact{
  display: inline-block;
}
.ul.top-social-buttons li a:active, ul.top-social-buttons li a:focus, ul.top-social-buttons li a:hover {
  background-color: #fe8903;
}
.top-oncontact:hover{
  color: #fe8903;
}
.list-inline.top-contact-list{
  float: left !important;  
}
.top-contact, .top-social{
  margin-top: 2rem !important;
}
.list-inline .top-oncontact{
  margin-right: 5px !important;
}
@media (max-width: 767px) {
  .top-social{
    display: none;
  }
  .hero-text{
    margin-top: 1rem !important;
  }
  .top-oncontact{
    display: inline !important;
    font-size: 0.9rem;
  }
}
/* --------- */
/* Home Section  */
/* --------- */
.hero-solution-content{
  width: 100%;
  height: 90vh;
}

.home-about-content h3{
  padding-top: 1rem;
  text-align: left;
  font-weight: bold;
  font-size: 2.5rem;  
  color: rgb(250, 250, 250);
}
.home-about-para p{
  padding-top: 0.75rem;
  padding-bottom: 1.5rem;
  text-align: left;
  font-size: 1.1rem;
  color: antiquewhite;
}
.home-about-content{
  text-align: left;
}
.home-counts1, .home-counts2, .home-counts3 {
  border: 1.5px solid rgba(236, 230, 238, 0.877) !important;
  padding: 0.75rem 0.75rem 0.45rem 0.45rem;
  margin-right: 0.4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}
.home-counts1 h4, .home-counts2 h4, .home-counts3 h4 {
  font-weight: bold;
  font-size: 3.5rem;
  color: rgba(235, 62, 10, 0.877);
}
.home-counts1 p, .home-counts2 p, .home-counts3 p {
  font-size: 1.1rem;
  color: rgba(253, 251, 251, 0.877);
}

.trending-tech-icon{
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 10px !important;
}
.trending-tech-list{
  display: inline-block !important;
  padding-left: 25px;
  padding-right: 25px;
}
.trending-tech-list a, .trending-tech-list a:hover{
  text-decoration: none;
  color: inherit;
}
.tech_text{
  color: rgba(243, 239, 247, 0.979);
  font-size: 1.1rem;
}
/* .tranding-tech_img{
  filter: invert(100%) sepia(71%) saturate(0%) hue-rotate(180deg) brightness(106%) contrast(102%);
} */
@media (max-width: 767px) {
  .hero-solution-content{
    width: 100%;
    height: 90vh;
  }
  .hero-animation{
    display: none;   
  }
  .o-hero-title{
    font-size: 0.8rem;
  }
  .textAnimation{
    font-size: 1.8rem;
  }
  .trending-tech-content{
    overflow-x: scroll;
  }
  .home-about-section{
    margin-top: 6rem;
  }
  .section-title{
    margin-bottom: 0.8rem;
  }
  .home-about-content h3{
    font-size: 1.6rem;
    padding-top: 0.5rem
  }
  .home-about-para p{
    font-size: 1rem;
  }
  .home-about-para a{
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .solutionover-hedding h4{
    font-size: 1.8rem !important;
    line-height: 1.8rem !important;
  }
  .solution-icon-one, .solution-icon-two{
    width: 50% !important;
  }
  .solution-architecture{
    max-width: 40% !important;
    height: 50% !important;
  }
}
/* --------- */
/* Our Products Section  */
/* --------- */
.othersproduct{
  margin-top: 3rem !important;
  margin-bottom: 1rem !important;  
}
.our-subujproduct-col{
  padding: 5px;
  background: linear-gradient(to right, red, yellow);
	margin: 30px;
	box-shadow: 0px 0px 30px red, 0px 0px 10px yellow;
	animation: animate 3s infinite;
  padding-left: 0 !important; 
  padding-right: 0 !important;
  border-radius: 2rem;
}
@keyframes animate{
  0%{
    background: linear-gradient(to right, red, yellow);
    box-shadow: 0px 0px 30px red,
          0px 0px 10px yellow;
  }

  50%{
    background: linear-gradient(to right, yellow, red );
    box-shadow: 0px 0px 30px yellow,
        0px 0px 10px red;
  }

  100%{
    background: linear-gradient(to right, red, yellow);
    box-shadow: 0px 0px 30px red,
          0px 0px 10px yellow;
  }
}
.our-subujproduct-content, .our-pos-content, .our-education-content{
  background: linear-gradient(to right, rgb(10, 4, 22), rgb(17, 2, 48));  
  border-radius: 2rem;
}
.our-pos-col, .our-education-col{
  width: 48% !important;
  padding: 5px;
  background: linear-gradient(to right, #e9750a, #FCC201);
	margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 7px;
  margin-left: 7px;
	box-shadow: 0px 0px 40px #e9750a, 0px 0px 20px #FCC201;
	animation: animatepos 3s infinite;
  padding-left: 0px !important; 
  padding-right: 0px !important;
  border-radius: 2rem;
}
@keyframes animatepos{
  0%{
    background: linear-gradient(to right, #e9750a, #FCC201);
    box-shadow: 0px 0px 40px #e9750a,
          0px 0px 20px #FCC201;
  }

  50%{
    background: linear-gradient(to right, #FCC201, #e9750a );
    box-shadow: 0px 0px 40px #FCC201,
        0px 0px 20px #e9750a;
  }

  100%{
    background: linear-gradient(to right, #e9750a, #FCC201);
    box-shadow: 0px 0px 40px #e9750a,
          0px 0px 20px #FCC201;
  }
}
.subujproduct-logo{
  flex-shrink: 0;
	margin: 20px;
	height: calc(100px + 5vw);
	width: calc(100px + 5vw);
	border: calc(8px + 0.3vw) solid transparent;
	background-origin: border-box;
	background-clip: content-box, border-box;
	background-size: cover;
	box-sizing: border-box;
	box-shadow: 0 0 5px 5px rgba(24, 129, 148, 0.5);
  margin-top: 15px!important;
  background-color: rgb(230, 215, 215);  
}
.headshot-3 {
  border-radius: 50% 10%;
  border: 4px solid rgba(19, 48, 26, 0.979); 
}
.subujproduct-title{
 width: 80%!important;
}
.subujproduct-logo{
  width: 18%;
}
.o-sub-title{
  margin-left: 170px !important;
}
.subujproduct-content{
  padding-top: 10px;
}
.subujproduct-content p{
  text-align: left;
  font-size: 1.1rem;
  color: antiquewhite;
  padding-left: 20px;
}
.subujproduct-title.o-title-block.position-relative{
  margin-top: 20px !important;
}
/* --------- */
/* Contact Us Section  */
/* --------- */
.career-email{
  text-align: left;
  margin-top: 2.5rem;
  color:aliceblue;
  font-weight: bold;
}
.career-phone{
  text-align: left;
  margin-top: 1rem;
  color:aliceblue;
  font-weight: bold;
}
.add-ind, .add-usa{
  text-align: left;
  margin-top: 1rem;
  color:aliceblue;
}
.contact-content-info h4{
  color: rgb(224, 98, 14);
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.contact-content-info{
  text-align: left;
}
.contact-info h7, .career-info h7, .ind-address-info h7, .usa-address-info h7{
  color: rgb(160, 70, 168);
}
.contact-info, .career-info, .ind-address-info, .usa-address-info{
  text-align: center;
}

.project-dicuss-content{
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 5rem;
}
.project-dicuss{
  height: 18rem;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-left: 12rem;
  margin-right: 12rem;
  flex-wrap: wrap;
  border-radius: 15px;
  background: linear-gradient(to right, rgba(209, 14, 144, 0.5),rgba(29, 9, 211, 0.5));
}
.project-dicuss p{
  color: antiquewhite;
  font-size: 1.3rem;
  padding-bottom: 8px;
  margin-left: 1rem;
  margin-right: 1rem;
}
.project-dicuss h4{
  font-size: 2.3rem;
  font-weight: bold;
  color: antiquewhite;
}
.project-center{
  justify-content: center;
}
.product-hire{
  height: 15rem;
  width: 50%;
  justify-content: center;
  padding-top: 2rem;
  border: 1px solid rgb(58, 54, 54) !important;
  text-align: left;
}
.product-hire-work{
  height: 15rem;
  width: 50%;
  justify-content: center;
  padding-top: 2rem;
  text-align: left;
  background: linear-gradient(to right, rgba(62, 159, 189, 0.5),rgba(92, 82, 187, 0.5));
}
.product-hire-work h4, .product-hire h4{
  color: aliceblue;
  padding-bottom: 0.7rem;
  padding-left: 0.5rem;
}
.product-hire h4 span{
  color: rgb(230, 65, 15);
  font-weight: bold;
  font-size: 2rem;
}
.product-hire-work h4 span{
  color: rgb(230, 65, 15);
  font-weight: bold;
  font-size: 2rem;
}
.product-hire-content{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.solution-hire{
  height: 15rem;
  width: 50%;
  justify-content: center;
  padding-top: 2rem;
  border: 1px solid rgb(58, 54, 54) !important;
  text-align: left;
}
.solution-hire-work{
  height: 15rem;
  width: 50%;
  justify-content: center;
  padding-top: 2rem;
  text-align: left;
  background: linear-gradient(to right, rgba(3, 7, 63, 0.5),rgba(117, 124, 223, 0.5),rgba(206, 9, 68, 0.5));
}
.solution-hire-work h4, .solution-hire h4{
  color: aliceblue;
  padding-bottom: 0.7rem;
  padding-left: 0.5rem;
}
.solution-hire h4 span{
  color: rgb(230, 65, 15);
  font-weight: bold;
  font-size: 2rem;
}
.solution-hire-work h4 span{
  color: rgb(230, 65, 15);
  font-weight: bold;
  font-size: 2rem;
}
.solution-hire-content{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 767px) {
  .contact-content-info{
    margin-bottom: 25px;  
  }
  .news-content-field{
    padding-bottom: 20px;
  }
  .news-content-title{
    margin-bottom: 15px;
  }
  .solution-hire, .product-hire{
    margin-bottom: 25px;
  }
  .project-dicuss h4{
    font-size: 1.8rem;
  }
  .project-dicuss p{
    font-size: 1rem;
  }
  .newsanimation{
    display: none;
  }
  
  
}
/* --------- */
/* Nesw Letter Section  */
/* --------- */
.news-content-field{
  background-color: #181a27; 
  
}
.news-content-field h4 {
  color: aliceblue;
  padding-top: 1rem;
  font-family: 'Barlow Semi Condensed', 'sans-serif';
}
.infobtn{
  border-radius: 1rem;
  background-color: rgb(230, 65, 15);
  margin-bottom: 3rem;
  
}
.skype, .email, .phone span{
  color:aliceblue;
  font-weight: bold;
}
.skype {
  text-align: left;
  margin-top: 2.5rem;
}
.email, .phone{
  text-align: left;
  margin-top: 1rem;
}
input[type="text"], input[type="textarea"] {
  background: transparent;
  border: none;
  border-bottom: 1px solid #fff;
   -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}
.msg{
   background: transparent !important;
  border: none;
  border-bottom: 1px solid #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  margin-top: 2rem;
}
.msg:focus, select.msg:focus{
  -webkit-box-shadow: none;
  box-shadow: none;
}
input[type="text"]:focus,
select.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.md-3{
  margin-top: 2rem;
}
@media (max-width: 767px) {
  .infobtn{
    border-radius: 0;    
    margin-bottom: 0;    
  }
  .infobtn.menu-item.nav-item{
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  
}
/* --------- */
/* Services Section  */
/* ---UIUX Design---*/
.uiuxdesign-content{
  margin-top: 6rem;
  margin-bottom: 3rem;
}
.uiuxdesign{
  border-radius: 35px;
  border-color: aliceblue;
  background: linear-gradient(90deg, #2D9EE0 0%, #2F80ED 50%, #06489b 100%);
  padding-top: 60px;
  padding-bottom: 60px;
}
.icon-text{
  color: antiquewhite;
}
.react-parallax-bgimage{
  width: 400px !important;
  position: absolute;  
}
.uiux-icon{
  margin-top: 0.2em !important;
  margin-bottom: 1.8em !important;
  padding: 0 !important;
}
.uiux-icon-list{
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}
.uiux-design h4, .graphic-design h4{
  font-family: var(--PoppinsNormal);
  color: antiquewhite;
  margin-bottom: 1.8rem;
  font-size: 2rem !important;
}
.graphic-icon{
  margin-top: 0.2em !important;
  margin-bottom: 1.8em !important;
  padding: 0 !important;
}
.graphic-icon-list{
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}
.design-types {
	 padding: 2rem 0;
}
 @media only screen and (min-width: 768px) {
	 .design-types {
		 padding: 1rem 0;
	}
}
 @media only screen and (min-width: 1500px) {
	 .design-types {
		 padding: 2rem 0 1rem;
	}
}
 @media only screen and (min-width: 1200px) and (max-width: 1499px) {
	 .design-types {
		 padding: 0.5rem 0 1rem;
	}
}
 .design-types h4 {
	 color: var(--secondary);
	 font-size: 1.4rem;
	 line-height: 1rem;
	 font-family: var(--PoppinsNormal);
	 position: relative;
	 padding: 0.6rem 0 0.6rem 0.5rem;
   margin-bottom: 0.5rem !important;
   color: antiquewhite;
}
 @media only screen and (min-width: 1200px) and (max-width: 1499px) {
	 .design-types h4 {
		 font-size: 1.3rem !important;
		 line-height: 1rem;
		 padding: 0.6rem 0 0.6rem 0.5rem;
	}
}
 @media only screen and (min-width: 768px) {
	 .design-types h4 {
		 font-size: 1.2rem !important;
		 line-height: 1rem;
	}
}
 @media only screen and (min-width: 1500px) {
	 .design-types h4 {
		 font-size: 1.5rem !important;
		 line-height: 1.3rem;
		 padding: 0.6rem 0 0.6rem 3rem;
	}
}
 .design-types h4:before {
	 content: '';
	 position: absolute;
	 left: 0;
	 top: 1rem;
	 width: 1.1rem;
	 height: 1.1rem;
	 border: 1px solid #fff;
	 border-radius: 99em;
}
 @media only screen and (min-width: 768px) {
	 .design-types h4:before {
		 top: 1rem;
		 width: 0.8rem;
		 height: 0.8rem;
	}
}
 @media only screen and (min-width: 1500px) {
	 .design-types h4:before {
		 top: 1.05rem;
		 width: 1.1rem;
		 height: 1.1rem;
	}
}
 @media only screen and (min-width: 1200px) and (max-width: 1499px) {
	 .design-types h4:before {
		 top: 1rem;
		 width: 1rem;
		 height: 1rem;
	}
}
.uxui-animation-col{
  margin-top: -120px;
}
.graph-animation-col{
  margin-top: -10px;
}
/*---Paralax Image---*/
.paralaximg{
  width: 100%;
  display: block;
  height: auto;
}
.vertical {
  width: 100%;
  height: 450px;
  overflow-x: hidden;    
}
.vertical:before {
  content: '';
  position: fixed;
  display: inline-block;
  padding: 4px;
  top: 10px;
  left: 50%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  color: white;
  z-index: 1000;
  transform: translate3d(-50%, 0, 0);
}
.vertical::-webkit-scrollbar {
  display: none;
}
.vertical {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.styles.parallaxImage {
  margin: 10% 0;
  width: 45%;
  background: none;
  
}

/* ---Web Development---*/
.webdevelop-content{
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.web-develop{
  border-radius: 35px;
  border-color: aliceblue;
  background: linear-gradient(90deg,  #9400D3 0%, #4B0082 100%);
  padding-top: 60px;
  padding-bottom: 60px;
}
.web-develop-tabs{
  margin-left: 18px;
  margin-right: 10px;
}
.web-develop-title h4{
  font-family: var(--PoppinsNormal);
  color: antiquewhite;
  margin-bottom: 1.8rem;
  font-size: 2rem !important;
  text-align: left !important;
  margin-left: 30px;
}
.web-develop-link{
  text-align: right;
}
.nav-item .nav-link{
  color: rgb(230, 215, 215);
}
.nav-link.active{
  background-color: transparent !important;
  color: aliceblue !important;
  border-bottom: 2px solid #ca7424;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
  color: aliceblue !important;
}
.nav.nav-tabs{
  border-top: 1px solid #b57fcc;
  border-bottom: 1px solid #b57fcc;
  padding-top: 10px;
  padding-bottom: 10px;
}
.nav .nav-item .nav-link{
  font-size: 1.4rem !important;
}
.web-develop-icon{
  margin-left: 40px;
}
.web-develop-front-list h5{
  font-size: 1.2rem;
  color: rgb(248, 242, 242);
  margin-bottom: 30px;
}
/* ---Mobile App---*/
.mobileapp-content{
  margin-top: 3rem;
  margin-bottom: 6rem;
}
.mobile-app{
  border-radius: 35px;
  border-color: aliceblue;
  background: linear-gradient(90deg,  #185A9D 0%, #43CEA2 100%);
  padding-top: 60px;
  padding-bottom: 60px;
}
.mobile-app-tabs{
  margin-left: 18px;
  margin-right: 10px;
}
.mobile-app-title h4{
  font-family: var(--PoppinsNormal);
  color: antiquewhite;
  margin-bottom: 1.8rem;
  font-size: 2rem !important;
  text-align: left !important;
  margin-left: 30px;
}
.mobile-app-link{
  text-align: right;
}
.mobile-app-icon{
  margin-left: 40px;
}
.mobile-app-icons{
  margin-bottom: 15px;
}
.mobile-app-list h5{
  font-size: 1.2rem;
  color: rgb(248, 242, 242);
  margin-bottom: 30px;
}
.mobile-app-tabs .nav.nav-tabs{
  border-top: 1px solid #6c9ecf;
  border-bottom: 1px solid #6c9ecf;
  padding-top: 10px;
  padding-bottom: 10px;
}
/* ---Cloud Computing---*/
.cloud-com-content{
  border-radius: 35px;
  border-color: aliceblue;
  background: linear-gradient(90deg, #2D9EE0 0%, #2F80ED 50%, #06489b 100%);
  padding-top: 50px;
  padding-bottom: 50px;
}
.cloud-solutions{
  padding: 1.5rem 0 0;
  width: 50%;
  float: left;
}
.cloud-solutions h5{
  color: aliceblue;
}
.cloud-border{
  padding-top: 2rem;
  border-bottom: 1px solid #a9d2ec
}
.cloud-service-list{
  padding-top: 15px;
}
.cloud-service-list .design-types{
  padding-left: 15px;
}
.cloud-title h4{
  font-family: var(--PoppinsNormal);
  color: antiquewhite;
  margin-bottom: 1.8rem;
  font-size: 2rem !important;
  text-align: left !important;
  margin-left: 30px;
}
.iot-app-development{
  border-radius: 35px;
  border-color: aliceblue;
  background: linear-gradient(90deg, #4b6cb7,#182848);
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 15px;
  padding: 2.8rem 2.4rem;
}
.iot-app-development h6{
  text-align: left;
  font-size: 1.2rem;
  color: aliceblue;
  line-height: 1.7rem;
}
.wearable-app-development{
  border-radius: 35px;
  border-color: aliceblue;
  background: linear-gradient(90deg, #614385,#516395);
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 15px;
  padding: 2.8rem 2.4rem;
}
.wearable-app-development h6{
  text-align: left;
  font-size: 1.2rem;
  color: aliceblue;
  line-height: 1.7rem;
}
.ibeacon-app-development{
  border-radius: 35px;
  border-color: aliceblue;
  background: linear-gradient(90deg, #000c40,#607d8b);
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
  padding: 2.8rem 2.4rem;
}
.ibeacon-app-development h6{
  text-align: left;
  font-size: 1.2rem;
  color: aliceblue;
  line-height: 1.7rem;
}
.app-up-link{
  text-decoration: none;
}
.app-dev-link .btn{
  margin-top: 20px;
  margin-left: 40px;
}
/* --------- */

.business-modal-content{
  text-align: left;
}
.business-modal-content h3{
  color: aliceblue;
}

.service-link{
  text-decoration: none !important;
  color: #fff;
}
.service-link:hover{
  color: #be50f4;
}
.service-title{
  display: flex;
  position: absolute;
  margin-top: 1.2rem;
  margin-left: 1.2rem;
}
.d-block{
  display: block;
}
.business-service-content{
  margin-top: 2rem;
  padding: 5rem 0;
  position: relatives;
}
.service-btn{
  justify-content: center;
  padding-top: 2rem;
}
.service-icon{
  padding-left: 25px;
  transform: rotate(-20deg);
}
.d-icon{
  border-radius: 16px;
  border: 1.8px solid rgb(253 252 252 / 95%);
  width: 50px;  
  height: 35px;
  margin-left: 55px;
}
.service-slide-content p{
  padding-top: 2.5rem;
  font-size: 25px;
  color: antiquewhite;
}
.slick-prev:before, .slick-next:before{
  font-size: 55px !important;
}
.slick-next {
  right: 10px !important;
}

.service-con-icon{
  padding: 0 !important;
  margin-top: 2.7rem;
  margin-bottom: 2.7rem;
}
.service-con-list{
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}
.infobtn.menu-item.nav-item{
  margin-left: 120px;
}
/*----Service Slider------*/
.slick-slide.slick-active.slick-current + .slick-slide .web-app-service:before {
  background: linear-gradient(90deg, #2d9ee0 0%, #2f80ed 50%, #091e3a 100%);
  transition-delay: 0.5s;
}
.slick-slide.slick-active.slick-current + .slick-slide .dev-ops-service:before {
  background: linear-gradient(90deg, #00467f, #a5cc82);
}
.slick-slide.slick-active.slick-current + .slick-slide .mobile-app-service:before {
  background: linear-gradient(90deg, #1d2671 0%, #c33764 100%);
}
.slick-slide.slick-active.slick-current + .slick-slide .uiux-desing-service:before {
  background: linear-gradient(to left, #4b0082, #9400d3);
}
.slick-slide.slick-active.slick-current + .slick-slide .crm-web-service:before {
  background: linear-gradient(to left, #43cea2, #185a9d);
}
.slick-slide.slick-active.slick-current + .slick-slide .data-science-service::before {
  background: linear-gradient(to left, #9400d3, #185a9d);
}

.slick-slide.slick-active.slick-current + .slick-slide.slick-active + .slick-slide.slick-active .mobile-app-service {
  background: linear-gradient(90deg, #4b0082, #9400d3) !important;
}

.first-slider .slick-slide > div {
  width: 90%;
  margin: 0 auto;
}
@media only screen and (max-width: 991px) {
  .first-slider .slick-slide > div {
    width: 95%;
 }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .first-slider .slick-slide > div {
    width: 80%;
 }
}
@media only screen and (max-width: 767px) {
  .first-slider .slick-slide > div {
    width: 95%;
 }
 .service-btn{
   display: block;
 }
 .service-slide-content p{
  font-size: 17px;
 }
 .slick-prev:before, .slick-next:before{
   display: none;
 }
 .d-flex.align-items-center h3{
   font-size: 12px !important;
 }
 .web-app-service, .mobile-app-service, .uiux-desing-service, .dev-ops-service, .crm-web-service, .data-science-service{
  height: 90px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
 }
 .web-app-service .service-title, .mobile-app-service .service-title, .uiux-desing-service .service-title, .dev-ops-service .service-title, .crm-web-service .service-title, .data-science-service .service-title{
  margin-left: 10px !important;
 }
 .service-icon{
   display: none !important;
 }
 .d-icon{
   margin-left: 15px;
   border-radius: none;
   border: none;
 }
}
.first-slider .slick-list {
  width: calc(100% + 3.5%);
  margin-left: -1.85%;
}

.second-slider .slick-arrow {
  right: 0;
  height: 4.4rem;
  width: 4.4rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #444;
  border-radius: 99px;
}
.second-slider .slick-prev {
  font-size: 0 !important;
  display: block;
  position: absolute;
  top: 40%;
  left: -35%;
  border-radius: 99px;
  background-color: rgb(10, 4, 22) !important;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .second-slider .slick-prev {
    left: -70px;
 }
}
@media only screen and (max-width: 767px) {
  .second-slider .slick-prev {
    display: none !important;
 }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .second-slider .slick-prev {
    left: -40%;
 }
}
.second-slider .slick-prev:before {
  content: '' !important;
  /* background-image: url("./services-icons/right-arrow.svg"); */
  transform: rotate(180deg);
  background-repeat: no-repeat;
  background-position: top 49% right 1.3rem;
  height: 4.4rem;
  width: 4.4rem;
  display: block;
  border-radius: 99px;
}
.second-slider .slick-prev:hover:before {
  background-color: var(--primary) !important;
}
.second-slider .slick-next {
  font-size: 0 !important;
  display: block;
  position: absolute;
  top: 40%;
  right: -35%;
  border-radius: 99px;
  background-color: #111 !important;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .second-slider .slick-next {
    right: -70px;
 }
}
@media only screen and (max-width: 767px) {
  .second-slider .slick-next {
    display: none !important;
 }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .second-slider .slick-next {
    right: -40%;
 }
}
.second-slider .slick-next:before {
  background-color: transparent;
  content: '' !important;
  /* background-image: url("./services-icons/right-arrow.svg"); */
  background-repeat: no-repeat;
  background-position: top 49% right 1.3rem;
  height: 4.4rem;
  width: 4.4rem;
  display: block;
  border-radius: 99px;
}
.second-slider .slick-next:hover:before {
  background-color: var(--primary) !important;
}

.mobile-app-service {
  position: relative;
  /* width: 100%; */
  background: linear-gradient(to left, #4b0082, #9400d3) !important;
  border-radius: 13px;
  overflow: hidden;
  height: 170px;
  border: 1.2px solid rgba(178, 87, 214, 0.877);
  margin-left: 20px;
  margin-right: 20px;
}
.mobile-app-service:hover:before {
  background: linear-gradient(to left, #4b0082, #9400d3);
}
.mobile-app-service:before {
  top: 1px;
  left: 1px;
  margin: 0;
  content: '';
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-color: rgb(10, 4, 22);
  border-radius: 13px;
}

.web-app-service {
  position: relative;
  /* width: 100%; */
  background: linear-gradient(90deg, #2d9ee0 0%, #2f80ed 50%, #091e3a 100%);
  overflow: hidden;
  height: 170px;
  border-radius: 13px;
  border: 1.2px solid rgba(178, 87, 214, 0.877);
  margin-left: 20px;
  margin-right: 20px;
}
.web-app-service:hover:before {
  background: linear-gradient(90deg, #2d9ee0 0%, #2f80ed 50%, #091e3a 100%);
}
.web-app-service:before {
  top: 1px;
  left: 1px;
  margin: 0;
  content: '';
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-color: rgb(10, 4, 22);  
  border-radius: 13px;
}

.dev-ops-service {
  position: relative;
  /* width: 100%; */
  background: linear-gradient(90deg, #00467f, #a5cc82);
  height: 170px;
  border-radius: 13px;
  border: 1.2px solid rgba(178, 87, 214, 0.877);
  margin-left: 20px;
  margin-right: 20px;
  overflow: hidden;
}
.dev-ops-service:hover:before {
  background: linear-gradient(90deg, #00467f, #a5cc82);
}
.dev-ops-service:before {
  top: 1px;
  left: 1px;
  margin: 0;
  content: '';
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-color: rgb(10, 4, 22);
  border-radius: 13;
}

.uiux-desing-service {
  position: relative;
  /* width: 100%; */
  background: linear-gradient(90deg, #1d2671 0%, #c33764 100%);
  height: 170px;
  border-radius: 13px;
  border: 1.2px solid rgba(178, 87, 214, 0.877);
  margin-left: 20px;
  margin-right: 20px;
  overflow: hidden;
}
.uiux-desing-service:hover:before {
  background: linear-gradient(90deg, #1d2671 0%, #c33764 100%);
}
.uiux-desing-service:before {
  top: 1px;
  left: 1px;
  margin: 0;
  content: '';
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-color: rgb(10, 4, 22);
  border-radius: 13px;
}

.crm-web-service {
  position: relative;
  /* width: 100%; */
 /*background: linear-gradient(90deg, #704E8D 0%, #CA5DB9 100%);
 */
  background: linear-gradient(90deg, #185a9d 0%, #43cea2 100%);
  height: 170px;
  border-radius: 13px;
  border: 1.2px solid rgba(178, 87, 214, 0.877);
  margin-left: 20px;
  margin-right: 20px;
  overflow: hidden;
}

.crm-web-service:hover:before {
  background: linear-gradient(90deg, #185a9d 0%, #43cea2 100%);
}
.crm-web-service:before {
  top: 1px;
  left: 1px;
  margin: 0;
  content: '';
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-color: rgb(10, 4, 22);
  border-radius: 13px;
}

.data-science-service {
  position: relative;
  /* width: 100%; */
  background: linear-gradient(to left, #4b0082, #d36600) !important;
  border-radius: 13px;
  overflow: hidden;
  height: 170px;
  border: 1.2px solid rgba(178, 87, 214, 0.877);
  margin-left: 20px;
  margin-right: 20px;
}
.data-science-service:hover:before {
  background: linear-gradient(to left, #4b0082, #d36600);
}
.data-science-service:before {
  top: 1px;
  left: 1px;
  margin: 0;
  content: '';
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-color: rgb(10, 4, 22);
  border-radius: 13px;
}

.services-box-main {
  margin-top: 2rem;
  padding: 5rem 0;
  position: relative;
}
@media only screen and (max-width: 991px) {
  .services-box-main {
    padding: 5rem 0 4rem;
 }
}
@media only screen and (max-width: 767px) {
  .services-box-main {
    overflow: hidden;
 }
}
.services-box-main:before {
  content: '';
  position: absolute;
  top: 0;
  left: 10%;
  margin: 0 auto;
  height: 100%;
  background: linear-gradient(90deg, #091024 0%, #1f1c4b 50%, #031936 100%);
  width: calc(100% - 20%);
}
.mobile-display-link{
  display: none;
}
@media only screen and (max-width: 991px) {
  .services-box-main:before {
    width: 100%;
    left: 0;
 }
}
@media only screen and (max-width: 767px) {
  .services-box-main:before {
    width: 100%;
    left: 0;
 }
}
@media only screen and (max-width: 575px) {
  .services-box-main:before {
    width: 100%;
    left: 0;
 }
}
@media (max-width: 767px) {
  .web-develop-para, .uxui-paralax, .web-develop-para, .mobile-app-link, .web-develop-link{
    display: none !important;
  }
  .uiuxdesign-content{
    margin-top: 3rem !important;
  }
  .design-types{
    padding: 1rem 0 !important;
  }
  .design-types h4{
    font-size: 1.2rem !important;
    line-height: 0.6rem;
  }
  .design-types h4::before{
    top: 0.8rem;
    width: 0.8rem;
    height: 0.8rem;
  }
  .uiuxdesign, .web-develop, .mobile-app{
    padding-top: 30px !important;
    padding-bottom: 20px !important;
  }
  .uiux-design h4, .graphic-design h4{
    margin-bottom: 5px !important;
  }
  .uiux-design a, .graphic-design a{
    margin-bottom: 1rem;
  }
  .web-develop-link a, .mobile-app-link a{
    margin-top: -20px;
    margin-bottom: 10px
  }
  .nav .nav-item .nav-link{
    font-size: 1rem !important;
  }
  .web-develop-front-list, .mobile-app-list{
    width: 50% !important;
    max-width: 50%;
  }
  .web-develop-front-list h5, .mobile-app-list h5{
    margin-bottom: 12px;
    margin-top: -10px !important;
  }
  
  .web-develop-front-list a, .mobile-app-list a{
    margin-bottom: 25px !important;
  }
  .cloudcom-content{
    margin-top: -35px;
  }
  .cloud-com-content{
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .cloud-title h4{
    margin-bottom: 1rem !important;
  }
  .cloud-border{
    padding-top: 1rem !important;
  }
  .iot-app-development{
    height: 150px;
    margin-top: 20px;
  }
  .wearable-app-development, .ibeacon-app-development{
    height: 150px;
  }
  .app-dev-title, .app-dev-link{
    width: 50% !important;
    margin-top: -15px !important;
  }
  .mobile-display-link{
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

/* --------- */
/* Navbar Section  */
/* --------- */
.fixed-top{
background-color: rgb(10, 4, 22);
}
.sticky {
  background-color: #212038d8 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}
.menu-item{
  border-radius: 7px;
  margin-bottom: 0;  
}
.menu-item.infobtn{
  background-image: linear-gradient(to right, #f5ce62, #e43603, #fa7199, #e85a19);
  box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
}
.menu-item:focus{
  outline: none;
}
.menu-item:hover{
  background-position: 100% 0;    
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  padding: 0.25rem 1.5rem !important;
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #be50f4 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #181a27 !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}
@media (max-width: 420px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 2.4em !important;
  width: 4.5em !important;
}

.navbar-nav .nav-link {
  color: rgb(247, 241, 241) !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.ml-auto.navbar-nav{
  padding-left: 120px;
}


.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #c95bf5;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
  padding-top: 1px;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}
/* --------- */
/* pages Section */
/* --------- */
.page-mid-container{
  margin-top: 4rem;
  margin-bottom: 2rem;
}
.page-mid-title h4{
  font-size: 3rem;
  line-height: 3rem;
  color: antiquewhite;
  margin-top: 1.5rem;
}
.page-mid-title p{
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: antiquewhite;
  margin-top: 1.5rem;
}
.page-mid-box{
  width: 30% !important;
  border: 1px solid aliceblue;
  border-radius: 15px;
  margin-top: 10px !important;
  margin-bottom: 40px !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
  box-shadow: 4px 5px 4px 3px rgba(164, 102, 221, 0.14) !important;
  opacity: 0.93 !important;
}
.page-mid-box h5{
  font-size: 2rem;
  color: antiquewhite;
  margin-bottom: 0.6rem;
}
.page-mid-box p{
  font-size: 1.2rem;
  color: aliceblue;
}
.page-mid-box .glow-on-hover.btn.btn-default{
  margin-bottom: 8px;
}
/* .page-icon-img{
  padding-left: 50px !important;
  margin-top: -10px !important;
  margin-bottom: -20px !important;
} */
@media only screen and (max-width: 767px) {
  .page-mid-title h4{
    font-size: 2rem;
    line-height: 2rem;
  }
  .page-mid-box{
    width: 45% !important;    
  }
  .page-mid-box h5{
    font-size: 1.4rem;
  }
  .page-mid-box p{
    font-size: 1rem;
  }
}
/* --------- */
/* pages2 Section */
/* --------- */
.page-design-title h4{
  font-size: 3rem;
  line-height: 3rem;
  color: aliceblue;
  text-align: left;
  margin-bottom: 3rem;
}
.page-design-title p{
  color: antiquewhite;
  text-align: left;
  font-size: 1.2rem;
}
.page-design-mid h5{
  font-size: 2.3rem;
  color: aliceblue;
  text-align: left;
  margin-top: 3.5rem;
  margin-bottom: 2rem;
}
.page-design-mid p{
  color: antiquewhite;
  text-align: left;
  font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 2rem
}
@media only screen and (max-width: 767px){
  .page-design-title h4{
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
  .page-design-title p, .page-design-mid p{
    font-size: 1.1rem;
  }
  .page-design-mid h5{
    font-size: 1.5rem;
  }
}
/*----Choose Us------*/
.chooseoverview-content{
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.chooseoverview-title h4{
  font-size: 2rem;
  color: azure;
}
.chooseoverview-title p{
  font-size: 1.1rem;
  color: aliceblue;
  text-align: left;
}
.choose-architecture h5{
  font-size: 1.8rem;
  color: azure;
  text-align: left;
}
.choose-architecture p{
  font-size: 1.1rem;
  color: aliceblue;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .chooseoverview-title h4{
    font-size: 1.6rem;
  }
  .chooseoverview-title p{
    font-size: 1rem;
  }
  .choose-architecture h5{
    font-size: 1.4rem;
  }
  .choose-architecture  p{
    font-size: 1rem;
  }
}
/* --------- */
/* Solutions Section */
/* --------- */
.btn-circle.btn-xl {
    width: 45px;
    height: 45px;
    padding: 10px 0px;
    border-radius: 23px;
    border-color: bisque;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}
.btn-circle.btn-xl:hover{
  background-color: #be50f4;
}
.solutionover-hedding h4{
  color: aliceblue;
  text-align: left;
  font-size: 3rem;
  line-height: 3rem;
  margin-top: 0.6rem;
  margin-bottom: 1rem;
}
.solutionover-para{
  margin-top: 0.5rem;
}
.solutionover-para p{
  color: antiquewhite;
  text-align: left;
  font-size: 1.2rem;
}
.solution-icon-two{
  margin-top: 5rem !important;
}
.solution-enterprise-one, .solution-enterprise-two, .solution-enterprise-three, .solution-enterprise-fore{
  background-color: #181a27;
  height: 162px;
  width: 180px;
  border-radius: 1rem;
  justify-items: center !important;
  padding-top: 2rem;
}
.solution-enterprise-one:hover{
  background: linear-gradient(to right, rgba(209, 14, 144, 0.5),rgba(29, 9, 211, 0.5));
}
.solution-enterprise-two:hover{
  background: linear-gradient(to right, rgba(76, 8, 131, 0.37),rgba(11, 169, 197, 0.5));
}
.solution-enterprise-three:hover{
  background: linear-gradient(to right, rgba(223, 32, 58, 0.884),rgba(119, 74, 22, 0.884));
}
.solution-enterprise-fore:hover{
  background: linear-gradient(to right, rgba(55, 114, 223, 0.5),rgba(5, 64, 68, 0.5));
}
.solution-architecture{
  background-color: #181a27;
  width: 200px;
  height: 235px;
  font-size: 1.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  vertical-align: middle !important;
  text-align: left !important;
  border-radius: 8px !important;
  display: table !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}
.solution-architecture:hover{
  background: linear-gradient(to right, rgba(24, 59, 124, 0.5),rgba(90, 206, 214, 0.5));
}
.solution-architecture h5{
  color: antiquewhite;
  padding-top: 0.6rem;
  padding-bottom: 1rem;
}
.solution-enterprise-one h4, .solution-enterprise-two h4, .solution-enterprise-three h4, .solution-enterprise-fore h4 {
  color: aliceblue;
  font-size: 1.5rem;
}
.solution-enterprise-one, .solution-enterprise-two{
  margin-right: 0.5rem !important;
}
.solution-enterprise-three, .solution-enterprise-fore{
  margin-left: 0.5rem !important;
}
.solution-enterprise-two, .solution-enterprise-fore{
  margin-top: 1rem !important;
}
.demand-solution{
  height: 25rem;
  background: #181a27;
  padding: 2rem;
  text-align: left;
  border-radius: 20px;
}
.demand-solution p{
padding-left: 2rem;
padding-right: 2rem;
font-size: 1.2rem;
color: aliceblue;
}
.demand-solution h3{
  padding-left: 2rem;
  padding-bottom: 1rem;
  color: antiquewhite;
}
.solutions-demand-icons{
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 2px, 2px, 2px, 2px !important;
}
.demand-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 1.2rem;
  font-weight: bold;
  color: antiquewhite;
  margin-right: 0.5rem;
}
.demand-icons::before{
  color: transparent;
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  border: 1px solid var(#fff);
  border-radius: 30px;
  background-color: rgb(206, 64, 64);
 background-image: url("./maps-and-flags.png");
 margin-left: -1.5rem;
}
.industry-solution-content{
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.flex-column{
  padding: 0, 0, 0, 0 !important;
}
.solnav.nav-link.active{
  background-color: rgb(10, 4, 22) !important;
  color: rgb(230, 65, 15) !important;
}
.solnav.nav-link{
  color: rgb(250, 250, 250) !important;
  font-size: 1.5rem;
  text-align: left;
}
.industry-solution-col-content h5{
  font-size: 2.5rem;
  font-weight: bold;
  color: antiquewhite;
  text-align: left;
}
.industry-solution-col-content p{
  text-align: left;
  color: antiquewhite;
}
.industry-icons{
  font-size: 1.2rem;
  font-weight: bold;
  color: rgb(212, 140, 44);
  text-align: left;
}
.industry-solution-col-img1{  
  height: 320px !important;
  text-align: left;
}
.industry-solution-col-img2{  
  height: 320px !important;
  text-align: left;
}
.industry-col-img1{ 
  width: 455px; 
  height: 330px !important;
  aspect-ratio: auto;
  border-radius: 2rem;
  text-align: left;
}
.solution-industry-col-img1{
  height: 155px !important;
  border-radius: 2.2rem;
  text-align: left;
}
.solution-industry-col-img2{  
  height: 155px !important;
  border-radius: 2.2rem;
  text-align: left;
  margin-top: 15px !important; 
}
.sol-col2{
  margin-top: 1rem;
}
@media (max-width: 767px) {
  .demand-solution p{
    font-size: 1rem;    
  }
  .demand-solution{
    height: 32rem !important;
  }
  .demand-icons{
    font-size: 1rem !important;
  }
  .industry-solution-col-2{
    display: none !important;
  }
  .tabname{
    width: 40% !important;
    max-width: 40% !important;
    height: 310px !important;
    overflow: scroll;
  }
  .industry-solution-col-content h5{
    font-size: 1.7rem !important;
  }
  .industry-icons{
    font-size: 1rem !important;
  }
  .tabcontent{
    width: 60% !important;
    max-width: 60% !important;
  }
}
/* --------- */
/* About Section */
/* --------- */
.overview-text p{
  font-size: 1.1rem;
  font-family: "RobotoRegular";
}
.our-vision h4, .our-mission h4{
  font-size: 1.8rem;
}
.our-vision p, .our-mission p{
  font-size: 1.1rem;
  font-family: "RobotoRegular";
  text-align: left;
}
.outsource-grid p{
  font-size: 1.1rem;
  font-family: "RobotoRegular";
  text-align: left;
}
.benefits-icons{
  background: linear-gradient(90deg, #185A9D 0%, #43CEA2 100%);
  padding: 0 0 0 1rem;
  width: calc(100% - 4rem);
  height: 180px;
  margin: 1rem 1rem 1rem 1rem;
  border-radius: 1rem;
  display: block;
  border: 1.7px solid rgba(178, 87, 214, 0.877) !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
}

.benefits-icons:hover {
  
  border: 2px solid rgba(251, 245, 253, 0.877) !important;
  background:linear-gradient(90deg,  #1D2671 0%, #C33764 100%);  
}
.benefits-icons img{
  margin-top: 25px;
}
.benefits-icons h5{
  margin-top: 15px;
}
.overview-text p {
  color: rgba(251, 245, 253, 0.877);
  text-align: left;
  
}
.overview-content h2 {
  
  color: rgba(235, 62, 10, 0.877);
}
.our-mission h4, .our-mission p, .our-vision h4, .our-vision p{
  color: rgba(251, 245, 253, 0.877);
}

.benefits-icons h5 {
  color: rgba(251, 245, 253, 0.877);
}
.benefits-content h2 {
  color: rgba(235, 62, 10, 0.877);
}
.techexpert-content h2 {
  color: rgba(235, 62, 10, 0.877);
}
.techexpert-content h4 {
  color: rgba(251, 245, 253, 0.877);
}
.tech-icons h5 {
  color: rgba(251, 245, 253, 0.877);
}
.outsource-drone{
  margin-bottom: -40px;
}
.outsource-grid h4, .outsource-grid p{
  color: rgba(251, 245, 253, 0.877);
  text-align: left;
}
.success-content h2 {
  color: rgba(235, 62, 10, 0.877);
}
.success-counts1, .success-counts2, .success-counts3 {
  border: 1.5px solid rgba(236, 230, 238, 0.877) !important;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  margin-right: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  background:linear-gradient(rgba(245, 59, 59, 0.5),transparent);
}
.success-counts1 h4, .success-counts2 h4, .success-counts3 h4 {

  font-weight: bolder;
  font-size: 5rem;
  color: rgba(235, 62, 10, 0.877);
}
.success-counts1 p, .success-counts2 p, .success-counts3 p {
  font-size: 1rem;
  color: rgba(253, 251, 251, 0.877);
}
@media (max-width: 767px) {
  .benefits-icons.col-3{
    width: 40% !important;
  }
  .benefits-icons h5{
    font-size: 1rem;
  }
  .success-img img{
    height: 20vh !important;
    padding-bottom: 20px;
  }
}
/* --------- */
/* Footer */
/* --------- */
.footer {    
  padding: 2rem 0 0 0;
  background-color: #212038d8;
  margin-top: 4rem;
  }
.footercopy {
    background-color: #212038d8;
    padding-top: 7px;
  }
  .footer-copywright {
    text-align: center !important;
  }
  
  .footer-mid {
    text-align: center !important;
  }
  .text-copy {
    font-size: 1rem !important;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: aliceblue;
  }
  .list-inline-item a{
    font-size: 1rem !important;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(63, 64, 65);
    text-decoration: none;
  }
  .footer-body a{
    font-size: 0.9rem !important;
  }
  .footer-title {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
 }
 .footer-link{
  color: rgb(119 121 122);
  text-decoration: none;
 }
 .footer-one{
   margin-bottom: 10px;
 }
.footer-link:hover, .list-inline-item a:hover{
  color: rgba(235, 62, 10, 0.877);
}
.ul.social-buttons {
  margin-bottom: 0;
}
.ul.social-buttons li a:active, ul.social-buttons li a:focus, ul.social-buttons li a:hover {
  background-color: rgba(235, 62, 10, 0.877);
}
.list-inline-item.icon-item a {
  font-size: 20px;
  line-height: 40px;
  display: block;
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: #fff;
  border-radius: 10px;
  outline: 0;
  background-color: #1a1d20;
}
.footersocial-icons{
  color: rgba(235, 62, 10, 0.877);  
}
.add-location{
  font-weight: bold;
}
  @media (max-width: 767px) {
    .footer-copywright {
      text-align: center !important;
    }
  
    .footer-mid {
      text-align: center !important;
    }
  }
  
  .footer h4 {
    font-size: 1em;
    color: white !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }
  
  .blockquote-footer {
    color: #a588c0 !important;
  }

  .footercont {
    padding: 1rem;
    color: #fff;
    text-align: center;
  }
  .footer-icons {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
    padding: 0 !important;
  }
  .social {
    padding-top: 2rem;
  }
  .social-list{
    text-align: center !important;
    margin: 2rem 2rem 2rem 2rem;
  }
  
.social-icons{
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.quick-links {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
}

/* ----Tech Experts----- */
  .tech-icons {
    font-size: 1.5em !important;
    margin: 15px !important;
    padding: 10px !important;
    opacity: 0.93 !important;
    border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
    vertical-align: middle !important;
    text-align: center !important;
    border-radius: 5px !important;
    display: table !important;
    box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
  }
.tech-icons:hover{
  background:linear-gradient(rgba(105, 43, 221, 0.5),transparent);
}
  button{
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}
/*---Testimonial*/
.review{
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.testimonial .slick-list {
  padding: 1.85rem 0;
}
.testimonial .slick-dots li.slick-active button:before {
  color: #8e6ff6;
}
.testimonial__div {
  padding-top: 2rem;
}
.testimonial__div .fa-quote-left {
  width: 55px;
  height: 55px;
  line-height: 45px;
  top: -28px;
  right: 23px;
}
.text-st{
  color: rgb(248, 242, 242);
}
.text-justify.o-font-sm{
  color: rgb(238, 236, 234);
}


.o-btn{
  background: #8E6FF6;
  width: 145px;
}
.o-btn::before{
      position: absolute;
      content: '';
      width: 145px;
      height: 40px;
      border: 2px solid #8E6FF6;
      left: -6px;
      top: 6px;
      transition: all .3s;
  }

.o-btn:hover::before{
      left: 0;
      top: 0;
  }
  .o-text-purple{
    color: #8E6FF6;
  }
  
  .o-border-purple{
      border: 1px solid #8E6FF6 !important;
  }
  
  .o-bg-purple{
      background: #8E6FF6 !important;
  }
  .o-bg-black{
    background: #03000c !important;
  }

  .nav-item:hover .dropdown-menu{
    display:block!important
  }
  .solution-enterprise-one h4, .solution-enterprise-two h4, .solution-enterprise-three h4, .solution-enterprise-fore h4{
    margin-top: 8px !important;
  }
  a:link {
    text-decoration: none;
  }